<template>
  <nut-navbar @on-click-back="returnClick()" title="注册"></nut-navbar>
  <div class="content">
    <div class="blank10"></div>
    <div class="blank10"></div>
    <div class="normal-formbox newform">
      <form :model="forgetform">
        <nut-row>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机号"
                @change="change"
                v-model="forgetform.phone"
                @clear="clear"
                type="number"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入验证码"
                @change="change"
                v-model="forgetform.code"
                @clear="clear"
            />
            <div class="piccode" @click="getIdentifyingCode(true)">
              <img
                  id="picCode"
                  ref="img"
                  style="height: 30px; width: 80px; cursor: pointer"
                  :src="forgetform.imgSrc"
              />
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入手机验证码"
                @change="change"
                v-model="forgetform.phonecode"
                @clear="clear"
            />
            <div
                id="getPhoneCodeDiv"
                :style="forgetform.phone == '' ? style : ''"
                class="piccode"
            >
              <nut-button
                  plain
                  id="getPhoneCode"
                  type="info"
                  size="small"
                  @click="getPhoneCode(true)"
                  :disabled="forgetform.phone == '' ? isDisabled : false"
              >发送验证码
              </nut-button>
            </div>
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="请输入密码"
                @change="change"
                v-model="forgetform.psw"
                type="password"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item">
            <nut-input
                placeholder="邀请码"
                @change="change"
                v-model="forgetform.invitationCode"
                :readonly="isReadonly"
                type="invitationCode"
                @clear="clear"
            />
          </nut-col>
          <nut-col :span="24" class="form-item lg-btn">
            <nut-button
                plain
                type="info"
                class="blue-btn"
                @click="reg()"
                :disabled="!protoCheck"
                :isloading="isloading"
            >{{ btnContent }}
            </nut-button>
          </nut-col>
        </nut-row>
      </form>
    </div>
    <!-- 服务协议 -->
    <div class="plany-box">
      <nut-checkbox v-model="protoCheck" label=""
      >我同意<span @click.stop="openPro1">《SView用户使用协议》</span>和<span
          @click.stop="openPro2"
      >《隐私政策》</span
      ></nut-checkbox>

    </div>
  </div>
</template>
<script>
import {reactive, toRefs, ref, onMounted} from 'vue'

import {useRoute, useRouter} from 'vue-router'

import axios from 'axios'
import {Notify} from '@nutui/nutui'

export default {
  name: 'register',

  setup() {
    const route = useRoute()
    const protoCheck = ref(true)
    const router = useRouter()
    //禁用=true
    const isloading = ref(false);
    const btnContent = ref('立即注册');
    //只读=true
    const isReadonly = ref(false);
    let Base64 = require('js-base64').Base64

    onMounted(() => {
      //获取邀请码
      if (route.query.invitationCode !== undefined
          && route.query.invitationCode.length > 0) {
        forgetform.invitationCode = route.query.invitationCode;
        //邀请码文本框只读
        isReadonly.value = true;
      }
      getGraphicVerificationCode();
      timer();
    })

    //发送验证码div
    const style = {'pointer-events': 'none'}
    //发送验证码按钮
    const isDisabled = ref(true)
    const forgetform = reactive({
      phone: '',
      psw: '',
      code: '',
      phonecode: '',
      invitationCode: '',
      imgSrc: '',
      codeAccount: '',
    })
    // 方法调用
    const change = (value, event) => {
      console.log('change: ', value, event)
    }
    const clear = (value) => {
      console.log('clear:', value)
    }
    const getIdentifyingCode = (value) => {
      getGraphicVerificationCode()
    }
    //获取图形验证码
    const getGraphicVerificationCode = () => {
      const url = 'https://service.sview3d.com/service/user/kaptcha'
      axios
          .post(url)
          .then(function (res) {
            forgetform.imgSrc = 'data:image/jpg;base64,' + res.data.img
            forgetform.codeAccount = res.data.uuid
          })
          .catch((res) => {
            console.log('失败')
          })
    }
    // 手机验证码
    const getPhoneCode = () => {
      const url = 'https://service.sview3d.com/service/user/getVerificationCode'
      const params = new URLSearchParams()
      params.append('phone', forgetform.phone)
      params.append('code', forgetform.code)
      params.append('account', forgetform.codeAccount)
      //校验手机号是否正确
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/
      if (!phoneRegular.exec(forgetform.phone)) {
        Notify.warn('请输入正确的手机号!')
        return false
      }

      axios
          .post(url, params)
          .then(function (res) {
            if (res.data.return == '0' || res.data.return == 0) {
              Notify.success('验证码已发送')
            } else {
              Notify.warn(res.data.msg)
              getGraphicVerificationCode()
            }
          })
          .catch((res) => {
            console.log('失败')
          })
    }
    const reg = () => {
      //校验手机号是否正确
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/
      if (!phoneRegular.exec(forgetform.phone)) {
        Notify.warn('请输入正确的手机号!')
        return false
      }
      isloading.value = true;
      btnContent.value = '正在注册';
      const url = 'https://service.sview3d.com/service/user/reg'
      const params = new URLSearchParams()
      //1、个人用户；2、企业用户，此处默认传“1”
      params.append('type', 'phone')
      params.append('phone', forgetform.phone)
      params.append('verify_code', forgetform.phonecode)
      params.append('password', forgetform.psw)
      params.append('invitationCode', forgetform.invitationCode)
      axios
          .post(url, params)
          .then(function (res) {
            if (res.data.return == '0' || res.data.return == 0) {
              Notify.success('注册成功,请填写企业信息登记')
              //o,userid
              const userid = res.data.userid
              router.push({
                path: '/phoneWeb/CorporateInfoSet',
                query: {
                  phone: forgetform.phone,
                  innerId: Base64.encode(userid),
                },
              })
            } else {
              Notify.warn(res.data.msg)
              isloading.value = false;
              btnContent.value = '立即注册';
            }
          })
          .catch((res) => {
            Notify.warn(res.data.msg)
            isloading.value = false;
            btnContent.value = '立即注册';
          })
    }
    const timer = () => {
      const getPhoneCodeDiv = document.getElementById('getPhoneCodeDiv')
      const getPhoneCode = document.getElementById('getPhoneCode')
      var timer = null
      getPhoneCode.addEventListener('click', function () {
        //style="pointer-events: none"
        getPhoneCodeDiv.style.pointerEvents = 'none'
        isDisabled.value = true
        let num = 60
        getPhoneCode.innerHTML = num + 's后重新发送'
        timer = setInterval(function () {
          num--
          getPhoneCode.innerHTML = num + 's后重新发送'
          if (num == 0) {
            clearInterval(timer)
            getPhoneCodeDiv.style.pointerEvents = ''
            isDisabled.value = false
            getPhoneCode.innerHTML = '发送验证码'
          }
        }, 1000)
      })
    }
    // 打开用户协议
    const openPro1 = () => {
      router.push('/phoneWeb/useragreement')
    }
    // 打开隐私政策
    const openPro2 = () => {
      router.push('/phoneWeb/privacypolicy')

    }
    //返回登录页面
    const returnClick = () => {
      router.push('/phoneWeb/')
    }

    return {
      forgetform,
      getIdentifyingCode,
      change,
      clear,
      getPhoneCode,
      reg,
      getGraphicVerificationCode,
      timer,
      style,
      isDisabled,
      returnClick,
      openPro2,
      openPro1,
      protoCheck,
      isloading,
      btnContent,
      isReadonly
    }
  },
}
</script>
<style scoped>
</style>
